import { __awaiter, __generator } from "tslib";
import "./index.scss";
import { defineComponent, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import BasisGroup from "@/views/basisGroup/index.vue";
import HomeJumbotron from "@/views/home/homeJumbotron/index.vue";
import { productImgApi } from "@/api/product";
import { sendMailApi } from "@/api/email";
import Nav from "@/components/nav/nav.vue";
export default defineComponent({
    components: { BasisGroup: BasisGroup, Nav: Nav, HomeJumbotron: HomeJumbotron },
    setup: function () {
        var _this = this;
        document.documentElement.scrollTop = 0;
        var navActiveIndex = ref("2-1");
        var route = useRoute();
        var productInfo = ref("");
        var productImg = ref("");
        var routeQuery = route.query;
        if (route.query.productInfo) {
            var str = decodeURIComponent(routeQuery.productInfo);
            productInfo.value = JSON.parse(str);
        }
        //根据产品id查询图片
        var getProductImg = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = productImg;
                        return [4 /*yield*/, productImgApi({
                                productId: productInfo.value.id,
                            })];
                    case 1:
                        _a.value = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        //模态框调用
        var dialogVisible = ref(false);
        var content = ref("");
        var sendMail = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, sendMailApi({
                            cc: [],
                            content: content.value,
                            subject: "来自因格艾官方网站产品咨询",
                            to: "services@yingeai.com"
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var tipShow = function () {
            dialogVisible.value = true;
        };
        var close = function (typeName, submitConsultData, phone, email) {
            if ((typeName != null && submitConsultData != null) && (phone != null || email != null)) {
                content.value = "----咨询产品名称:" + typeName + "----咨询项目:" + submitConsultData.substring(2, submitConsultData.length - 2) + "----email地址:" + email + "----手机号码:" + phone;
                //提交
                sendMail();
            }
            //TODO 取消
            dialogVisible.value = false;
            console.log(dialogVisible.value, "close-props");
        };
        onMounted(function () {
            getProductImg();
        });
        return {
            tipShow: tipShow,
            close: close,
            productInfo: productInfo,
            productImg: productImg,
            navActiveIndex: navActiveIndex,
            dialogVisible: dialogVisible
        };
    },
});
